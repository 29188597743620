'use client'

import { FlashMessageContext } from '@/context/flash-message'
import { useContext, useEffect, useMemo, useRef } from 'react'
import { useLocalStorage } from 'react-use'
import { tosBannerLocalStorageId } from 'ui/src/constants'
import { CloseIcon } from 'ui/src/icons/navigation'

const tosClassName = 'bg-gradient-warning rounded-xl md:rounded-none drop-shadow-xl mt-2 mx-2 md:mt-0 md:mx-0'
function TosBanner() {
    const [hasSeenTosBanner, setHasSeenTosBanner] = useLocalStorage(tosBannerLocalStorageId, false)
    const { showMessage, hideMessage } = useContext(FlashMessageContext)
    const isShowing = useRef(false)
    const Message = useMemo(
        () => (
            <div className="inline-flex flex-1 items-center justify-between lg:whitespace-nowrap">
                <div className="my-4 flex-1 text-sm md:my-0 md:text-base lg:text-xl">
                    <span className="mr-2 font-extrabold">👋</span>
                    <span className="mr-2">Hi!</span>
                    <span className="font-normal">
                        We&apos;ve recently updated our{' '}
                        <a href="/privacy" className="font-bold underline">
                            Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a href="/terms" className="font-bold underline">
                            Terms of Service
                        </a>
                        . By continuing to use GIPHY, you are agreeing to these updates.
                    </span>
                </div>
                <CloseIcon
                    height={20}
                    className="cursor-pointer justify-end pl-3"
                    onClick={() => {
                        setHasSeenTosBanner(true)
                        hideMessage()
                    }}
                />
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
    useEffect(() => {
        window.didomiOnReady = window.didomiOnReady || []
        window.didomiOnReady.push(function (Didomi) {
            // @ts-ignore
            if (Didomi.isConsentRequired()) {
                // Consent is required: your visitor is from the EU or you are an EU company
                // Only enable the vendor when consent is given
                // @ts-ignore
                Didomi.getObservableOnUserConsentStatusForVendor('c:googleana-4TXnJigR').subscribe(function (
                    consentStatusForVendor: undefined | boolean
                ) {
                    if (consentStatusForVendor !== undefined) {
                        // The consent status for the vendor is unknown
                        if (!hasSeenTosBanner && !isShowing.current) {
                            isShowing.current = true
                            setTimeout(() => {
                                showMessage({ message: Message, className: tosClassName, displayTime: 100000000 })
                            }, 1250)
                        }
                    }
                })
            } else {
                // Consent is not required, enable your vendor immediately
            }
        })
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [])
    return null
}

export default TosBanner
