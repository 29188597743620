'use client'
import UAParserContext from '@/context/ua-parser'
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
type SearchbarContext = {
    autoFocus: boolean
}
export const SearchbarContext = createContext({
    autoFocus: false,
} as SearchbarContext)

const SearchbarContextManager = ({ children }: { children: ReactNode }) => {
    const { deviceType } = useContext(UAParserContext)
    // only auto focus once
    const [autoFocus, setAutoFocus] = useState(deviceType === 'desktop')
    useEffect(() => {
        setAutoFocus(false)
    }, [])
    const value = useMemo(() => ({ autoFocus }), [autoFocus])
    return <SearchbarContext.Provider value={value}>{children}</SearchbarContext.Provider>
}

export default SearchbarContextManager
