'use client'
import { createGlobalStyle, css } from 'styled-components'
import { headerClassname } from 'ui/src/components/nav/desktop/dropdown'
import { baseCss, giphyCss, ssStandardCss } from 'ui/src/css'
import { Interface, NexaBlack, SSStandard } from './fonts'

const nextCss = css`
    body {
        font-family: ${Interface.style.fontFamily};
        -webkit-font-smoothing: antialiased;
    }
    #didomi-host h1 {
        font-family: ${NexaBlack.style.fontFamily};
        font-weight: 200 !important;
        margin-bottom: 8px !important;
    }
    ${headerClassname} {
        font-family: ${NexaBlack.style.fontFamily};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        a {
            font-weight: 400;
        }
    }
`

const GlobalCss = createGlobalStyle`
${baseCss}
${giphyCss}
${ssStandardCss(SSStandard.style.fontFamily)}
${nextCss}
`

export default GlobalCss
