'use client'
import Button from '@/components/button'
import { Modal } from './modal'
import { giphyUrl } from '@/app/util/env'
import { useState } from 'react'
import { useLocalStorage } from 'react-use'

export function BetaModal() {
    const [seen, hasSeen] = useLocalStorage('betaModal', true)
    const [show, setShow] = useState(true)
    const isBeta = giphyUrl.includes('beta.giphy.com')
    return isBeta && show && seen ? (
        <Modal
            title="Hey, CAUTION, WARNING, ALERT!"
            contentClassName="flex flex-1 flex-col"
            className="md:w-[600px]"
            onClose={() => {
                setShow(false)
                hasSeen(false)
            }}
        >
            <div className="flex flex-1 flex-col items-center justify-center gap-4 p-3 px-4 text-lg">
                <p className="text-center leading-5">
                    You have entered a <span className="text-giphyRed">GIPHY Beta Environment</span>. Beta uses{' '}
                    <span className="text-giphyRed">Production Data</span>, meaning anything you upload or edit{' '}
                    <span className="text-giphyRed">will also happen on GIPHY.com</span>. Proceed with Caution, because
                    you beta believe anything you mess up will be held against you.
                </p>
                <div className="flex gap-2">
                    <Button
                        className="bg-giphyRed"
                        onClick={() => {
                            location.href = `https://giphy.com${location.pathname}`
                        }}
                    >
                        Go to GIPHY.com
                    </Button>
                    <Button
                        onClick={() => {
                            setShow(false)
                            hasSeen(false)
                        }}
                    >
                        Continue to Beta
                    </Button>
                </div>
                <a
                    href="https://giphypedia.atlassian.net/wiki/spaces/WF/pages/1626603855/Using+Web+Beta+Environments"
                    target="blank"
                    className="underline"
                >
                    Learn More
                </a>
            </div>
        </Modal>
    ) : null
}
