'use client'

import styled from 'styled-components'
import { giphyDarkestGrey, giphyLightGrey, giphyWhite } from '@giphy/colors'
import { Interface } from '../../styles/fonts'
import { privacyTermsIndex } from 'ui/src/constants'

const FloatingContainer = styled.div`
    display: none;
    @media (min-width: 1348px) {
        display: flex;
        position: fixed;
        bottom: 10px;
        left: 10px;
        z-index: ${privacyTermsIndex};
        gap: 10px;
    }
    a {
        display: flex;
        background: ${giphyDarkestGrey};
        border-radius: 5px;
        padding: 4px 14px;
        color: ${giphyLightGrey};
        &:hover {
            color: ${giphyWhite};
        }
        font-family: ${Interface.style.fontFamily};
        font-size: 14px;
    }
`

const PrivacyTerms = () => {
    return (
        <FloatingContainer>
            <a href="/privacy">Privacy</a>
            <a href="/terms">Terms</a>
        </FloatingContainer>
    )
}

export default PrivacyTerms
