'use client'

import Script from 'next/script'
import DidomiUI from 'ui/src/components/didomi/didomi'

// TODO copied from @next/third-parties as it has type issues

export type DidomiIds = {
    default: string
    consentOnEngagement: string
}

export const DIDOMI_IDS: DidomiIds = {
    default: 'CnUtpQ7h', // user must click "Agree" button on Didomi notice
    consentOnEngagement: 'YjTZPQHB', // consent is automatically granted on scroll or click
}

type GTMParams = {
    gtmId: string
    dataLayer?: string[]
    dataLayerName?: string
    auth?: string
    preview?: string
    didomiIds?: DidomiIds
}

let currDataLayerName: string | undefined = undefined

export function GoogleTagManager(props: GTMParams) {
    const { gtmId, didomiIds = DIDOMI_IDS, dataLayerName = 'dataLayer', auth, preview, dataLayer } = props

    if (currDataLayerName === undefined) {
        currDataLayerName = dataLayerName
    }

    const gtmLayer = dataLayerName !== 'dataLayer' ? `$l=${dataLayerName}` : ''
    const gtmAuth = auth ? `&gtm_auth=${auth}` : ''
    const gtmPreview = preview ? `&gtm_preview=${preview}&gtm_cookies_win=x` : ''

    return (
        // Order is important to Didomi: https://developers.didomi.io/cmp/web-sdk/getting-started#script
        <>
            <DidomiUI />
            <Script
                type="didomi/javascript"
                id="_next-gtm-init"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,l){
                        w[l]=w[l]||[];
                        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                        ${dataLayer ? `w[l].push(${JSON.stringify(dataLayer)})` : ''}
                    })(window,'${dataLayerName}');`,
                }}
            />
            <Script
                type="didomi/javascript"
                id="_next-gtm"
                src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}${gtmLayer}${gtmAuth}${gtmPreview}`}
            />
            <Script
                id="didomi"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        function showBanner(id) {
                            window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
                            else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
                            var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
                            if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e,tgt){
                            var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+tgt;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("8878f527-7872-49a5-abd4-f6b2e56cb64a",id)})();
                        };
                        fetch('https://giphy.com/api/geolocation')
                            .then((resp) => resp.json())
                            .then(function(response) {
                            window.didomiConfig = {
                                    notice: {
                                        content: {
                                          dismiss: {
                                            en: 'Agree',
                                          },
                                          deny: {
                                            en: 'Disagree',
                                          },
                                          learnMore: {
                                            en: 'Manage Cookies',
                                          }
                                        }
                                    }
                                }
                              if(response.region === 'CA' || response.continentCode === "EU"){
                                showBanner("${didomiIds['default']}")
                              } else {
                                showBanner("${didomiIds['consentOnEngagement']}")
                              }
                            }).catch((err) => {
                                showBanner("${didomiIds['default']}")
                            })`,
                }}
            />
        </>
    )
}

export const sendGTMEvent = (data: Object) => {
    if (currDataLayerName === undefined) {
        console.warn(`@next/third-parties: GTM has not been initialized`)
        return
    }

    // @ts-ignore
    if (window[currDataLayerName]) {
        // @ts-ignore
        window[currDataLayerName].push(data)
    } else {
        console.warn(`@next/third-parties: GTM dataLayer ${currDataLayerName} does not exist`)
    }
}
